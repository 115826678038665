<template>
  <v-container>
<v-col>
    <v-row>
      <v-col class="s-col-form ">
        <v-radio-group v-model="filter.SecStatus" row hide-details class=" pt-0 s-col-form">
          <v-spacer></v-spacer>
          <v-radio label="Por Asistencia" :value="1" />
          <v-radio label="Por Encargado" :value="2" />
          <v-spacer></v-spacer>
        </v-radio-group>
      </v-col>
    </v-row>
</v-col>
<v-col>
    <v-row class="justify-center">
      <v-col v-if="isLevelArea" cols="12" md="3" class="s-col-form">
        <s-select-area-allow v-model="filter.AreID" />
      </v-col>
      <v-col v-else cols="12" md="3" class="s-col-form">
        <s-select-area label="Area" v-model="filter.AreID" :text="filter.AreName" />
      </v-col>
      <v-col cols="12" md="3" class="s-col-form">
        <s-toolbar-subject :filter="filterSubject" v-model="filter.IsbID" clearable />
      </v-col>
      <v-col cols="4" md="2" class="s-col-form">
        <s-date v-model="filter.InsDate" label="Inico" />
      </v-col>
      <v-col cols="4" md="2" class="s-col-form">
        <s-date v-model="filter.InsDateEnd" label="Fin" />
      </v-col>
      <v-col cols="4" md="2" class="s-col-form">
        <s-select-definition :disabled="filter.SecStatus == 2" :def="1076" v-model="filter.CtrStatus" label="Estado" />
      </v-col>
    </v-row>
    </v-col>

    <v-container>
    <v-row>
      <v-col>
        <s-crud
          :title="filter.SecStatus == 1 ? 'Asistencia por Eventos' : 'Encargado por Evento'"
          :config="config"
          noBorder
          noDark
          excel
          @rowSelected="rowSelected"
          @doubleClick="doubleClick"
          :filter="filter"
          ref="crudshow"
        >
          
        </s-crud>
      </v-col>
    </v-row>
    </v-container>
  </v-container>
</template>

<script>
import sToolbarSubject from "@/views/Management/Instruction/SToolbarSubject.vue";
import _sInstruction from "@/services/Management/InstructionService";
export default {
  components: {
    sToolbarSubject,
  },
  props: {
    isLevelArea: false,
    isLevelAdmin: false,
  },
  data() {
    return {
      userInfo: this.$fun.getUserInfo() || {},
      filter: {
        InsDate: null,
        InsDateEnd: null,
        IsbID: 0,
        AreID: 0,
        SecStatus: 2,
        CtrStatus: 1,
      },
      header: [],
    };
  },
  created() {
    var inf = this.$fun.getUserInfo();
    if (inf.CtrContract != null) {
      this.filter.AreID = inf.CtrContract.AreID;
      this.filter.AreName = inf.CtrContract.AreName;
    }

    this.changeHeader();
  },
  methods: {
    rowSelected(items) {
      this.$emit("rowSelected", items.length > 0 ? Object.assign({}, items[0]) : {});
    },
    doubleClick(item) {
      this.$emit("doubleClick", item);
    },
    refreshData() {
      this.$refs.crudshow.refresh();
    },
    changeHeader() {
      this.header = [];
      if (this.filter.CtrStatus == 1)
          this.header.push({
            text: "Fecha",
            value: "InsDate",
          });
        this.header.push({
          text: this.filter.SecStatus == 2 ? "Encargado" : "Asistente",
          value: "NtpFullName",
        });
      if (this.filter.SecStatus == 1)
        this.header.push({
          text: "Área",
          value: "AreAbbreviation",
        });
        this.header.push({
          text: "Tema",
          value: "IsbSubject",
        });
        this.header.push({
          text: "Tipo",
          value: "TypeInstructionText",
        });
      if(this.filter.SecStatus ==2)
      {
        this.header.push({
          text: "Archivo",
          vañue: "file"
        });
      }
    },
  },
  computed: {
    filterSubject() {
      return {
        AreID: this.filter.AreID,
      };
    },

    config() {
      return {
        service: _sInstruction,

        model: {
          TableID: "ID",
          InsID: "int",
          InsDate: "date",
          IasID: "int",
          IinID: "int",
          NtpID: "int",
          PrsDocumentNumber: "string",
          NtpFullName: "string",
          WkrID: "int",
          IsbID: "int",
          IsbSubject: "string",
          SecStatus: "status",
          TypeInstruction: "int",
          file: "",
          IinNamePerson: "string",
          IinDocumentNumber: "string",
        },
        headers: this.header,
      };
    },
  },
  watch: {
    "filter.AreID"(val) {
      this.filter.IsbID = 0;
      this.$emit('returnFilter',this.filter);
    },
    "filter.InsDate"(val) {
      this.$emit('returnFilter',this.filter);
    },
    "filter.InsDateEnd"(val) {
      this.$emit('returnFilter',this.filter);
    },
    "filter.CtrStatus"(val) {
      this.changeHeader();
    },
    "filter.SecStatus"(val) {
      this.changeHeader();
    },
  },
};
</script>
