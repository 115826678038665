<template>
  <v-container>
    <v-row>
      <v-col>
        <div v-if="isLevelArea || isLevelAdmin">
          <s-toolbar-crud
            title="Eventos"
            add
            :edit="entity.InsID > 0"
            remove
            :service="service"
            @save="save"
            @showFormEvent="showForm"
            :entity="entity"
            @refresh="refresh"
          >
            
            <template v-slot:options="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" small @click="reportByWorker()">
                    <v-icon style="font-size: 16px">
                      mdi-arrow-down</v-icon
                    ></v-btn
                  >
                </template>
                <span>Reporte por Trabajador</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" small @click="reportByWorker()">
                    <v-icon style="font-size: 16px">
                      mdi-arrow-down</v-icon
                    ></v-btn
                  >
                </template>
                <span>Reporte por Trabajador</span>
              </v-tooltip> -->
            </template>
          </s-toolbar-crud>

          <v-tabs style="margin-top: 4px" :value="tab" vertical>
            <v-tab-item value="tab-show">
              <instruction-show
                @returnFilter="changeFilter($event)"
                :isLevelArea="isLevelArea"
                :isLevelAdmin="isLevelAdmin"
                @rowSelected="rowSelected"
                @doubleClick="doubleClick"
                ref="tabshow"
              />
            </v-tab-item>
            <v-tab-item value="tab-edit">
              <instruction-edit :value="entityShow" ref="edit" />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _sInstruction from "@/services/Management/InstructionService";
import InstructionEdit from "@/views/Management/Instruction/InstructionEdit.vue";
import InstructionShow from "@/views/Management/Instruction/InstructionShow.vue";

export default {
  components: {
    InstructionEdit,
    InstructionShow,
  },
  data() {
    return {
      filter: {},
      tab: "tab-show",
      entity: {},
      entityShow: {},
      isLevelArea: false,
      isLevelAdmin: false,
      service: _sInstruction,
    };
  },
  created() {
    this.filter.BeginDate = this.$fun.getDate();
    this.filter.EndDate = this.$fun.getDate();
    this.filter.AreID = 0;
    var sec = this.$fun.getSecurity();

    this.isLevelArea = sec.IsLevelArea;
    this.isLevelAdmin = sec.IsLevelAdmin;

    if (!(sec.IsLevelAdmin || sec.IsLevelArea))
      this.$fun.alert(
        "El usuario no tiene permisos para esta opción.",
        "warning"
      );
  },
  methods: {
    changeFilter(filter) {
      this.filter = filter;
    },
    showForm(event) {
      if (event.add || event.edit) {
        this.entityShow = event.add ? {} : this.entity;
        this.tab = "tab-edit";        
      } else {
        this.tab = "tab-show";
        this.refresh();
        this.entityShow = {};        
      }
    },
    reportByWorker() {
      console.log(this.filter);
      _sInstruction
        .reportByWorker(this.filter, this.$fun.getUserID())
        .then((r) => {
          this.$fun.downloadFile(
            r.data,
            this.$const.TypeFile.EXCEL,
            "Reporte Capacitaciones por Trabajador"
          );
        });
    },
    rowSelected(item) {
      this.entity = item;
    },
    doubleClick(item) {
      this.entity = item;
      showForm({ edit: true });
    },
    refresh() {
      this.$refs.tabshow.refreshData();
    },
    save() {
      this.$refs.edit.insSaveEntity();
      this.tab = "tab-show";
      
    },
  },
};
</script>

<style></style>
