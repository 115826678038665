<template>
	<v-card flat tile>
		<v-card-text>
			<v-container>
        <v-row> 
          <v-col cols="4" class="s-col-form">
            <s-date v-model="ins.InsDate" />
          </v-col>
          <v-col cols="4" class="s-col-form">
            <s-select-definition 
                label="Tipo Evento"
                :def="1067" 
                v-model="ins.TypeInstruction"
              />
          </v-col>
          <v-col cols="4" class="s-col-form">
            <s-select-campaign-se 
                label="Campaña"
                v-model="ins.CmpID"
              />
          </v-col>
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2">
            <s-select-definition 
                label="Modalidad"
                :def="1459" 
                v-model="ins.TypeModality"
              />
          </v-col>
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2">
            <s-select-definition 
                label="Tipo de Costo"
                :def="1460" 
                v-model="ins.TypeCost"
              />
          </v-col>
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2 && ins.TypeCost == 2">
            <s-text label="Monto de Costo" v-model.number="ins.AmountCost"   decimal/>
          </v-col> 
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2 && ins.TypeCost == 2">
            <s-select-definition 
                label="Moneda"
                :def="1129" 
                v-model="ins.TypeCurrency"
              />
          </v-col>
          
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2">
            <s-select-definition 
                label="Protocolo"
                :def="1461" 
                v-model="ins.TypeProtocol"
                :add="$fun.isAdmin()"
              />
          </v-col> 
          <v-col cols="2" class="s-col-form" v-if="ins.TypeInstruction==2">
            <s-select-definition 
                label="Gerencia"
                :def="1466" 
                v-model="ins.TypeGerency"
                :add="$fun.isAdmin()"
              />
          </v-col> 
          <v-col cols="12" class="s-col-form">
            <s-text label="Observaciones" v-model="ins.InsObservation" />
          </v-col> 
        </v-row>
				<v-row>
					<v-col cols="12" md="6">
						
						<s-crud
							title="Encargados"
							noDark
							formPermanent
							ref="crudInstruction"
							add
							edit
							remove
							noSearch
							noBorder
							:filter="filter"
							:config="iinConfig"
							@save="iinSave"
							@returnObject="returnObjectIin"
							class="py-0 mt-0 mb-n2"
							noConfimationSave
							noFooter
						>
						<template slot-scope="props">
							<v-container>
								<v-row class="mt-n3">
									<v-col cols="9" class="s-col-form">
										<s-toolbar-subject
											ref="tbIinSubject"
											add
											v-model="props.item.IsbID"
											@returnSubject="iinReturnSubject($event, props.item)"
										/>
									</v-col>
									<v-col cols="3" class="s-col-form">
										<s-text
											label="Duración (min)"
											number
											:min="min"
                      v-model="props.item.IinDurationTime"
                      readonly
										></s-text>
									</v-col>
									<!-- <v-col cols="3" class="s-col-form">
										<s-select-definition
											readonly
											:def="1067"
											v-model="props.item.TypeInstruction"
											label="Tipo"
										/>
									</v-col> -->
								</v-row>
								
								<v-row>
									<v-col cols="4" class="s-col-form">
										<s-text
											label="DNI"
											v-model="props.item.PrsDocumentNumber"
											@keyupEnter="keyUpEnter($event)"
										>
										</s-text>
									</v-col>
									<v-col cols="8" class="s-col-form">
										<s-text
											label="Encargado"
											v-model="NtpName"
											:readonly="hasWorker"
										/>
									</v-col>
								</v-row>

								<!-- <v-row>
									<v-col cols="4" class="s-col-form">
										<s-toolbar-person
											ref="tbIinPerson"
											v-model="props.item.PrsDocumentNumber"
											@returnPerson="iinReturnPerson($event, props.item)"
											:autofocus="false"
										/>
									</v-col>
									<v-col cols="8" class="s-col-form">
										<s-text
											disabled
											label="Encargado"
											v-model="props.item.NtpFullName"
										/>
									</v-col>
								</v-row> -->
							</v-container>
						</template>
						</s-crud>
					</v-col>
					<v-col cols="12" md="6">
						<v-card>
						<s-crud
							title="Asistencia"
							formPermanent
							ref="crudAttendance"
							noDark
							add
							edit
							excel
							remove
							noSearch
							noBorder
							:filter="filter"
							:config="iasConfig"
							@save="iasSave"
							@rowSelected="iasFocus"
							class="py-0 my-0"
							noConfimationSave
						>
							<template slot-scope="props">
							<v-container>
								<v-row class="mt-n3">
								<v-col cols="4" class="s-col-form">
									<s-toolbar-person
									ref="tbIasPerson"
									v-model="props.item.PrsDocumentNumber"
									@returnPerson="iasReturnPerson($event, props.item)"
									:autofocus="false"
									/>
								</v-col>
								<v-col cols="8" class="s-col-form">
									<s-text
									 
									label="Asistente"
									v-model="props.item.NtpFullName"
									/>
								</v-col>
								</v-row>
                <v-row>
                  <v-col sm="4">
                    <s-select-definition 
                      label="Tipo Trabajador"
                      :def="1039"
                      :dgr="4"
                      clareable
                      v-model="TypeWorker"
                    />
                  </v-col>
                
                  <v-col sm="8">
                      <s-select-area  
                        
                        label="Area de Postulacion*"  
                        :text="props.item.AreName"
                        v-model="AreID"
                       />
                  </v-col>
                
                </v-row>
							</v-container>
							</template>
						</s-crud>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
import _sInstruction from "@/services/Management/InstructionService";
import _sInstructionInstructor from "@/services/Management/InstructionInstructorService";
import _sInstructionAttendance from "@/services/Management/InstructionAttendanceService";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import sToolbarSubject from "@/views/Management/Instruction/SToolbarSubject.vue";

import _sPerson from '@/services/General/PersonService';
import _sSunarp from "@/services/General/SearchSunarp.js"

export default {
  components: {
    sToolbarPerson,
    sToolbarSubject,
  },
  props: {
    value: {
      default: {
        InsDate: "",
        InsObservation: "",
        InsID: 0,
      },
      required: true,
    },
  },
  data() {
    return {
      TypeWorker: '',
      AreID: '',
		NtpName : "",
		NtpPerson: {},
		hasWorker : false,
    worker : {},
		min: 1,
      ins: {
        InsDate: "",
        InsObservation: "",
        InsID: 0,
        TypeInstruction: 1,
      },
      iinConfig: {
        service: _sInstructionInstructor,
        model: {
          InsID: "int",
          IinID: "ID",
          NtpID: "int",
          PrsDocumentNumber: "string",
          NtpFullName: "string",
          WkrID: "int",
          IsbID: "int",
          IsbSubject: "string",
          SecStatus: "status",
        },
        headers: [
          {
            text: "Instructor",
            value: "IinNamePerson",
          },
          {
            text: "Tema",
            value: "IsbSubject",
            // width: "20%",
          },
          {
            text: "Tipo",
            value: "TypeInstructionText",
            // width: "20%",
          },
          {
            text: "Área",
            value: "AreAbbreviation",
            // width: "20%",
          },
          {
            text: "Duración (Min)",
            value: "IinDurationTime",
            // width: "20%",
          }, 
        ],
      },
      iasConfig: {
        service: _sInstructionAttendance,
        model: {
          InsID: "int",
          IasID: "ID",
          NtpID: "int",
          PrsDocumentNumber: "string",
          NtpFullName: "string",
          WkrID: "int",
          IsbID: "int",
          SecStatus: "status",
        },
        headers: [
          // {
          //   text: "ID",
          //   value: "IasID",
          //   align: "end",
          //   width: "15%",
          // },
          {
            text: "Doc",
            value: "PrsDocumentNumber",
            align: "center",
             
          },
          {
            text: "Asistente",
            value: "NtpFullName",
          },
          {
            text: "Área",
            value: "AreAbbreviation",
            // width: "30%",
          },
          {
            text: "Tipo Trabajador",
            value: "TypeWorkerName",       
          },
           
        ],
      },
    };
  },
  created() {
    console.log("carlos ato", this.value);
    this.ins = this.value;
    this.initialize();
  },
  watch: {
    value(val) {
      this.ins = val;
      this.initialize();
    },
  },
  computed: {
    filter() {
      return {
        InsID: this.ins.InsID,
      };
    },
  },
  methods: {
	keyUpEnter(item)
	{
		this.NtpName = '';
		_sPerson
		.getNaturalPerson(item, this.$fun.getUserID())
		.then(resp => {
			if(resp.status == 200)
			{

        console.log('sunexpert ', resp.data);
				if(resp.data != null)
				{
					let name = resp.data.NtpPaternalSurname + ' ' + resp.data.NtpMaternalSurname + ' ' + resp.data.NtpName;
					this.NtpName = name;
					this.hasWorker = true;
					this.NtpPerson = resp.data;
					
				}
				else
				{
					this.hasWorker = false;

          console.log("name", item);

          
				}
			}
		})
	},

    //GENERAL
    initialize() {
		if (this.value.InsID > 0) {
			_sInstruction.get(this.value, this.$fun.getUserID()).then((x) => {
				this.ins.InsObservation = x.data.InsObservation;
			});
		} else
			this.ins = {
			InsDate: this.$fun.getDate(),
			InsObservation: "",
			InsID: 0,
		};
    },

    //INSTRUCTION
    insValidate() {
      let isValid;
      let message;

      isValid = this.ins.InsDate.length > 0;
      if (!isValid) message = "Registre Fecha de evento";

      if (!isValid) this.$fun.alert(message, "warning");

      if(this.ins.TypeCost == 2 && this.ins.AmountCost <= 0){
        isValid = false;
        message = "Debe registrar un costo";
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },

    insSave() {
      this.ins.SecStatus = 1;
     
      return _sInstruction.save(this.ins, this.$fun.getUserID());
    },

    insSaveEntity() {
      if (this.insValidate())
        this.insSave().then((x) => {
          this.$fun.alert("Actualizado correctamente", "success");
        });
    },

    //INSTRUCTOR
    iinSubjectFocus(event) {
      // this.$refs.tbIinSubject.focus();
    },
    iinPersonFocus(event) {
      // this.$refs.tbIinPerson.focus();
    },
    iinCallSave() {
      this.$refs.crudInstruction.save();
    },
    iinReturnPerson(value, item) {
      if (value != null) {
        item.NtpFullName = value.NtpFullName;
        item.NtpID = value.NtpID;
        item.WkrID = value.WkrID;

        if (value.NtpID > 0) this.iinCallSave();
      } else {
        item.NtpFullName = "";
        item.NtpID = 0;
        item.WkrID = 0;
      }
      this.worker = item;
    },
    iinReturnSubject(value, item) {
      value = value || {};
      console.log('iinReturnSubject', value);
      item.IsbID = value.IsbID;
      item.TypeInstruction = value.TypeInstruction;

      console.log("", value);//samir
      item.IinDurationTime = value.IsbDuration;

      // this.$nextTick(function () {
      //   this.iinPersonFocus();
      // });
    },
    iinValidate(item) {
		let isValid;
		let message;

		isValid = item.PrsDocumentNumber.length > 0;
		if (!isValid) message = "Registre ponente/encargado";
		else {
			isValid = item.IsbID > 0;
			if (!isValid) message = "Registre tema";
		}

		if (!isValid) this.$fun.alert(message, "warning");

		return isValid;
    },
    iinSaveItem(item) {
      item.IinDocumentNumber = item.PrsDocumentNumber;
      item.IinNamePerson = this.NtpName;
      item.save();
      this.iinSubjectFocus();
    },
    returnObjectIin(item) {},
    iinSave(item) {
		item.InsID = this.ins.InsID;
		item.NtpID = this.NtpPerson.NtpID;
		item.WkrID = this.NtpPerson.WkrID;
    item.IinDocumentNumber = item.PrsDocumentNumber;
    item.IinNamePerson = this.NtpName;
    if(!this.hasWorker){
      item.NptID = null;
      item.WkrID = null;
    }

      if (this.iinValidate(item)) {
        // SI TIENE INSTRUCCION ENTONCES GUARDA EL DETALLE SI NO GUARDA PRIMERO LA INSTRUCCION Y LUEGO EL DETALLE
        if (item.InsID > 0) this.iinSaveItem(item);
        else {
          if (this.insValidate()) {
            this.insSave().then((r) => {
              this.ins.InsID = r.data.InsID;
              item.InsID = this.ins.InsID;
              //this.NtpName = "";
              if (item.InsID > 0) this.iinSaveItem(item);
            });
          }
        }
      }
    },
    // ATTENDANCE
    iasFocus(event) {
      // this.$refs.tbIasPerson.focus();
    },
    iasCallSave() {
      this.$refs.crudAttendance.save();
    },
    iasReturnPerson(value, item) {
      console.log("hola", item)
      console.log("value", value)

      
      if (value == null || value.PrsID == 0) {
        //samir
        item.NtpFullName = "";
        item.NtpID = 0;
        item.IasDocumentNumber = "";
        item.WkrID = 0;
        item.CtrID = 0;
 
        console.log("sunat", item.PrsDocumentNumber.length)
        if (item.PrsDocumentNumber.length > 7) {
              
          _sSunarp
              .Search(item.PrsDocumentNumber, this.$fun.getUserID())
              .then(resp => {
                if(resp.status == 200)
                {
                  console.log('repsyesta ', resp.data);
                  item.NtpFullName = resp.data.SunFatherName + ' ' + resp.data.SunMotherName + ' ' + resp.data.SunNames;
                  item.IasDocumentNumber = item.PrsDocumentNumber
                }
              })
        }
        
      }
      // if (value != null ) {
      else {
        item.NtpFullName = value.NtpFullName;
        item.NtpID = value.NtpID;
        item.IasDocumentNumber = value.PrsDocumentNumber;
       
        // if (value.NtpID > 0) this.iasCallSave();
      } 
       
      
    },
    iasValidate(item) {
      let isValid;
      let message;

      isValid = item.NtpFullName.length > 0;
      if (!isValid) message = "Registre asistente";

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    iasSaveItem(item) {
      item.TypeWorker = this.TypeWorker;
      item.AreID = this.AreID;
      item.save();
      this.iasFocus();
    },

    iasSave(item) {
      item.InsID = this.ins.InsID;

      if (this.iasValidate(item)) {
        // SI TIENE INSTRUCCION ENTONCES GUARDA EL DETALLE SI NO GUARDA PRIMERO LA INSTRUCCION Y LUEGO EL DETALLE
        if (item.InsID > 0) this.iasSaveItem(item);
        else {
          if (this.insValidate()) {
            this.insSave().then((r) => {
              this.ins.InsID = r.data.InsID || 0;
              item.InsID = this.ins.InsID;
              if (item.InsID > 0) this.iasSaveItem(item);
            });
          }
        }
      }
    },
  },
};
</script>
